<template>
  <!-- <a-drawer
    width="1200"
    :label-col="4"
    :wrapper-col="14"
    :visible="open"
    @close="onClose"
    :destroyOnClose="true">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider> -->
  <a-card :bordered="false">
    <!-- 条件搜索 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="小区名称">
              <a-input v-model="queryParam.community" placeholder="请输入" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
              <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
              <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 操作 -->
    <div class="table-operations">
      <a-button type="primary" @click="$refs.createForm.handleAdd(form)" v-hasPermi="['wisdom:deviceareacommunity:add']">
        <a-icon type="plus" />新增
      </a-button>
      <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['wisdom:deviceareacommunity:edit']">
        <a-icon type="edit" />修改
      </a-button>
      <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['wisdom:deviceareacommunity:remove']">
        <a-icon type="delete" />删除
      </a-button>
    </div>
    <!-- 增加修改 -->
    <create-form ref="createForm" @ok="getList(item, form)"/>
    <!-- 下级 -->
    <!-- <list4 ref="list4"/> -->
    <!-- 数据展示 -->
    <a-table
      :loading="loading"
      :size="tableSize"
      rowKey="id"
      :columns="columns"
      :data-source="list"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      :pagination="false"
      :bordered="tableBordered">
      <span slot="loglat" slot-scope="text, record">
        {{ `${record.log}, ${record.lat}` }}
      </span>
      <span slot="createTime" slot-scope="text, record">
        {{ parseTime(record.createTime) }}
      </span>
      <span slot="operation" slot-scope="text, record">
        <!-- <a @click="$refs.list4.getList(record,form)">
          <a-icon type="eye" />下级
        </a>
        <a-divider type="vertical" v-hasPermi="['wisdom:deviceareacommunity:edit']"/> -->
        <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['wisdom:deviceareacommunity:edit']">
          <a-icon type="edit" />修改
        </a>
        <a-divider type="vertical" v-hasPermi="['wisdom:deviceareacommunity:remove']"/>
        <a @click="handleDelete(record)" v-hasPermi="['wisdom:deviceareacommunity:remove']">
          <a-icon type="delete" />删除
        </a>
      </span>
    </a-table>
    <!-- 分页 -->
    <a-pagination
      class="ant-table-pagination"
      show-size-changer
      show-quick-jumper
      :current="queryParam.pageNum"
      :total="total"
      :page-size="queryParam.pageSize"
      :showTotal="total => `共 ${total} 条`"
      @showSizeChange="onShowSizeChange"
      @change="changeSize"
    />
  </a-card>
  <!-- </a-drawer> -->
</template>

<script>

import { communityPage, communityDel } from '@/api/pages/region'
import CreateForm from './CreateForm3'
import list4 from './list4'
import { tableMixin } from '@/store/table-mixin'
export default {
  mixins: [tableMixin],
  name: 'Config3',
  components: {
    CreateForm, list4
  },
  data () {
    return {
      formTitle: '',
      open: false,
      dataList: [],
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      refreshing: false,
      total: 0,
      // 日期范围
      dateRange: [],
      item: null,
      queryParam: {
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '小区名称',
          dataIndex: 'community',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '经纬度',
          scopedSlots: { customRender: 'loglat' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '添加时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 200,
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      form: {
        communityId: '',
        deviceAreaCityId: '',
        deviceAreaId: '',
        deviceAreaProvinceId: ''
      }
    }
  },
  filters: {
  },
  created () {
    // this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    onClose () {
      this.open = false
    },
    /** 查询参数列表 */
    getList (item, form) {
      this.loading = true
      this.item = item
      this.formTitle = item.label
      this.form = form
      // this.form.deviceAreaId = item.id
      console.log(item, form, '192>>>>>>')

      communityPage({ deviceAreaId: item.id, ...this.queryParam }).then(response => {
          this.list = response.data.records
          this.total = response.data.total
          this.loading = false
          this.open = true
        }
      )
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList(this.item, this.form)
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.dateRange = []
      this.queryParam = {
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList(this.item, this.form)
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList(this.item, this.form)
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const configIds = row.id ? [row.id] : this.ids
      this.$confirm({
        title: '提示',
        content: '确认删除所选中数据?',
        onOk () {
          return communityDel(configIds)
            .then(() => {
              that.onSelectChange([], [])
              that.$bus.$emit('refresh', true)
              that.getList(that.item, that.form)
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {}
      })
    }
  }
}
</script>
